import Layout from "@crafthunt-layout/Layout/Layout";
import LoadingScreen from "@crafthunt-ui/LoadingScreen/LoadingScreen";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import {
  ISbStoriesParams,
  StoryblokComponent,
  getStoryblokApi,
  useStoryblokState,
} from "@storyblok/react";
import { serverSideTranslationsWithLocale } from "@translations/helpers/server-side-translations.helper";
import { useMount } from "ahooks";
import { FloatingBanner } from "components/sections/JobAds/components/FloatingBanner";
import { ENV, isDev } from "constants/env";
import { useCandidate } from "context/CandidateContext";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import {
  GetStaticPaths,
  GetStaticProps,
  InferGetServerSidePropsType,
} from "next";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { LinkDef } from "types/links.type";
import { triggerAnalyticsEvent } from "utils/analytics";
import { getFullSlug } from "utils/router.helper";
import { fetchStoryblokSettings } from "utils/services/storyblok.service";

const DEFAULT_ROOT_SLUG = "land";

export default function Page({
  story,
  preview,
  locale,
  settings,
}: InferGetServerSidePropsType<typeof getStaticProps>) {
  const router = useRouter();
  const { resetCandidate } = useCandidate();
  const { t } = useTranslation();

  const showJobUnavailableBanner =
    router.isReady && router.query.from === "job-unavailable";

  useMount(() => {
    resetCandidate();

    if (story.content.conversionTrackingId) {
      triggerAnalyticsEvent(story.content.conversionTrackingId);
    }
  });

  // use the preview variable to enable the bridge only in preview mode
  const liveStory = useStoryblokState(story, { language: locale });
  const hasLiveStory = typeof liveStory?.content !== "undefined";

  if (preview && hasLiveStory) {
    story = liveStory;
  }

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (router.isFallback || !story) {
    return <LoadingScreen />;
  }

  // get default slug and translated slugs from story
  const translatedSlugs: TranslatedSlugDef[] = [
    {
      path: getFullSlug(story.default_full_slug || story.full_slug),
      lang: "de",
    },
    ...story.translated_slugs?.map(
      (translatedSlug) =>
        ({
          path: getFullSlug(translatedSlug.path),
          lang: translatedSlug.lang,
        }) as TranslatedSlugDef
    ),
  ];

  return (
    <Layout
      locale={locale}
      meta={story.content.metadata}
      settings={settings}
      translatedSlugs={translatedSlugs}
      hideNav={story.content.hideNavbar}
      customNavbarAction={story.content.customNavbarAction}
    >
      {showJobUnavailableBanner && (
        <div className="mx-auto bg-gray-50 px-2 pt-8 sm:px-6 lg:px-16">
          <FloatingBanner
            icon={ExclamationTriangleIcon}
            lg_text={t("job-ad-draft-redirect-message")}
            sm_text={t("job-ad-draft-redirect-message")}
          />
        </div>
      )}
      <StoryblokComponent
        blok={story.content}
        locale={locale}
        settings={settings}
      />
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({
  locale,
  params,
  preview = false,
}) => {
  const storyblokApi = getStoryblokApi();
  let slug = params.slug
    ? Array.isArray(params.slug)
      ? params.slug.join("/")
      : params.slug
    : "";
  const sbParams: ISbStoriesParams = {
    version: "published",
    language: locale,
    resolve_links: "url",
  };

  if (isDev || preview) {
    sbParams.version = "draft";
    sbParams.cv = Date.now();
  }

  if (slug && typeof slug === "string") {
    if (!slug.substring(0, 5).includes(DEFAULT_ROOT_SLUG)) {
      slug = `${DEFAULT_ROOT_SLUG}/${slug}`;
    }
  } else {
    slug = DEFAULT_ROOT_SLUG;
  }

  try {
    const [settings, story] = await Promise.all([
      // Fetch Global settings
      fetchStoryblokSettings(locale),
      // Fetch page content and resolve links as stories
      storyblokApi
        .get(`cdn/stories/${slug}`, {
          ...sbParams,
          resolve_links: "story",
        })
        .then(({ data }) => {
          return data.story;
        })
        .catch(async () => {
          // Try again without resolving links as stories
          const { data } = await storyblokApi.get(
            `cdn/stories/${slug}`,
            sbParams
          );

          return data.story;
        }),
    ]);

    return {
      props: {
        story,
        preview,
        locale,
        settings,
        ...(await serverSideTranslationsWithLocale(locale)),
      },
      revalidate: 60, // revalidate every minute
    };
  } catch (err) {
    console.error(
      "[storyblok:settings]",
      err.response?.data || err.response || err
    );

    return { notFound: true };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  if (ENV.SKIP_BUILD_STATIC_GENERATION) {
    return {
      paths: [],
      fallback: "blocking",
    };
  }

  const DEFAULT_ROOT_PAGE = "land/"; // important it ends with slash
  const storyblokApi = getStoryblokApi();
  const { data } = await storyblokApi.get("cdn/links/", {
    version: isDev ? "draft" : "published",
    starts_with: DEFAULT_ROOT_PAGE,
  } as ISbStoriesParams);

  const paths = [];
  Object.values(data.links).forEach((link: LinkDef) => {
    if (
      link.is_folder ||
      link.slug.includes("companies/") ||
      link.slug.includes("craftsmen/") ||
      link.slug.includes("signup-link/") ||
      link.slug.includes("personal-login-success") ||
      link.slug.includes("baugpt") ||
      link.slug.includes("magic-link") ||
      link.slug.includes("signup") ||
      link.slug.includes("blog/")
    ) {
      return;
    }

    const slug = link.slug.replace(DEFAULT_ROOT_PAGE, "");
    let splitSlug: string[] | false = slug.split("/");

    if (slug === "/" || slug === "") {
      splitSlug = false;
    }

    paths.push({
      params: { slug: splitSlug },
      locale: "de",
    });

    link.alternates?.forEach((alternate) => {
      let altSplitSlug: string[] | false = alternate.path.split("/");
      if (alternate.path === "/" || alternate.path === "") {
        altSplitSlug = false;
      }
      paths.push({ params: { slug: altSplitSlug }, locale: alternate.lang });
    });
  });

  return {
    paths,
    fallback: false,
  };
};
